.bgPrimary {
    background-color: $colorPrimary !important;
}

.bgSecondary {
    background-color: $colorSecondary !important;
}

.bgSuccess {
    background-color: $colorSuccess !important;
}

.bgDanger {
    background-color: $colorDanger !important;
}

.bgWarning {
    background-color: $colorWarning !important;
}

.bgInfo {
    background-color: $colorInfo !important;
}

.bgBlue {
    background-color: $colorBlue !important;
}

.bgGray {
    background-color: $colorGray !important;
}

.bgWhite {
    background-color: $colorWhite !important;
    border-bottom: 1px solid #DBE7EF;
}

.textPrimary {
    color: $colorPrimary !important;
}

.textSecondary {
    color: $colorSecondary !important;
}

.textSuccess {
    color: $colorSuccess !important;
}

.textDanger {
    color: $colorDanger !important;
}

.textWarning {
    color: $colorWarning !important;
}

.textInfo {
    color: $colorInfo !important;
}

.textBlue {
    color: $colorBlue !important;
}

.textGray {
    color: $colorGray !important;
}

.textWhite {
    color: $colorWhite !important;
}

.m-10 {
    margin: 0.625rem !important;
}

.mt-10 {
    margin-top: 0.625rem !important;
}

.mb-10 {
    margin-bottom: 0.625rem !important;
}

.ml-10 {
    margin-left: 0.625rem !important;
}

.mr-10 {
    margin-right: 0.625rem !important;
}

.m-20 {
    margin: 1.25rem !important;
}

.mt-20 {
    margin-top: 1.25rem !important;
}

.mb-20 {
    margin-bottom: 1.25rem !important;
}

.ml-20 {
    margin-left: 1.25rem !important;
}

.mr-20 {
    margin-right: 1.25rem !important;
}

.p-20 {
    padding: 1.25rem !important;
}

.pt-10 {
    padding-top: 0.625rem !important;
}

.pt-20 {
    padding-top: 1.25rem !important;
}

.pb-20 {
    padding-bottom: 1.25rem !important;
}

.pl-20 {
    padding-left: 1.25rem !important;
}

.pr-20 {
    padding-right: 1.25rem !important;
}

.fw10 {
    font-size: 0.625rem;
}

.fw12 {
    font-size: 0.75rem;
}

.fw18 {
    font-size: 1.125rem;
}

.fw16 {
    font-size: 1rem;
}

.fwbold {
    font-weight: bold !important;
}

.sliderDiv {
    padding: 10px;
}

.splide--draggable>.splide__track>.splide__list>.splide__slide {
    text-align: center !important;
}

.splide__pagination__page.is-active {
    background: $colorGray !important;
}

.splide__pagination__page.is-active {
    background: $colorPrimary !important;
}

.productWrapper {
    max-width: 100vw;
    overflow-x: hidden;
    width: 100%;

    @media (min-width:768px) {
        overflow-x: hidden;
        margin-top: 4.125rem;
    }

    .productScreen {
        margin-bottom: 4rem;
        // overflow: auto;

        @media (min-width:768px) {
            max-width: 40rem;
            overflow: hidden;
        }

        .swiper-wrapper {
            // height: auto !important;

            .swiper-slide {
                margin-top: .1rem;
                // max-height: 100vh;

                // @media (min-width:768px) {
                //     overflow: auto;
                // }
            }
        }
    }

    .bottomMenu {
        @media (min-width:768px) {
            max-width: 40rem;
        }
    }
}

.product__item {
    min-height: calc(100vh - (5rem + 5rem));
}


#root {
    background-color: #EFF8FD !important;

    @media (min-width:768px) {
        display: flex;
        justify-content: center;
    }

}


// section{
//     padding: 0 1.5rem;
//     background-color: $colorWhite;
//     margin: 0.2rem 0;
//     padding-bottom: 1rem;
//     border-bottom: 0.3rem solid $colorInfo !important;
//     >div:nth-last-child(2) {
//        margin-bottom: 1.25rem;
//        border-bottom:0 !important;
//       }
// }

section {
    padding: 0.1rem 1.5rem;
    background-color: $colorWhite;
    margin: 0.5rem 0;
    padding-bottom: .5rem;
    border-bottom: 0.07rem solid $colorInfo !important;

    @media (min-width:768px) {
        // padding: 0 2.3125rem;
    }

    >div:last-child {
        margin-bottom: 1.25rem;
        border-bottom: 0 !important;
    }
}

.banner {
    border-width: 1px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0rem;

    .row {
        margin: 1rem !important;
    }

    &__Title {
        color: $colorWhite;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: bold;
        display: block;
        text-align: center;
    }
}

.linkedUpid {
    text-decoration: underline !important;
}

.linked-section {
    padding: 0 1.5rem;
    background-color: $colorWhite;
    margin: 0.2rem 0;
    // padding-bottom: 1rem;
    border-bottom: 0.1rem solid $colorInfo !important;

    >div:nth-last-child(2) {
        margin-bottom: 1.25rem;
        border-bottom: 0 !important;
    }

    >div:nth-last-child(2).linkedProduct {
        padding-bottom: 0.625rem;
        border-bottom: 1px solid #B2C7D5 !important;
    }
}

.imageModal {
    z-index: 999999;

    .imageModalSwiper {
        .swiper-slide-active {
            max-height: 80vh;
        }
    }

    .modal-header {
        height: 0rem;
        padding: 0px;

        .modal-title {
            height: 0rem;
        }

        button {
            margin-top: 1.5rem;
            margin-right: 0.1rem;
            z-index: 2;
            background-color: white;
        }
    }

    .modal-body {
        padding: .1rem;
    }
}


.d3TreeModal {
    z-index: 999999;

    .modal-dialog {
        max-width: 40rem !important;
    }

    .modal-header {
        height: 0rem;
        padding: 0px;

        .modal-title {
            height: 0rem;
        }

        button {
            margin-top: 1.5rem;
            margin-right: 0.1rem;
            z-index: 2;
            background-color: white;
        }
    }

    .modal-body {
        padding: .1rem;
    }
}


.overlayUrlModal {
    width: 100vw;
    z-index: 999999999;
    height: 100%;

    .modal-body {
        padding: .1rem;
    }
}



.swiperSection {
    padding: 0;
    padding-bottom: 0rem;
    margin: 0.1rem;

    >div:last-child {
        margin-bottom: 0px;
        border-bottom: 0 !important;
    }
}

.imageSliderSection {
    // margin-top: 5.125rem;
    // padding-bottom: 0.5rem;
    margin-bottom: 0.6rem;


    .swiper-slide {
        height: 100%;
    }

    .imageSliderSection__item {
        display: flex !important;
        align-items: center;
        justify-content: center;
        height: 50vh;
        margin-bottom: 30px;

        .sliderImg {
            width: 100%;
            height: 50vh;
            object-fit: contain;
            // margin-bottom: 10px;
            border-radius: 10px;
            opacity: 1;
            background-color: transparent;
            text-align: center;
            // border: 1px solid transparent;
        }
    }

    .swiper-button-prev {
        height: 30%;
        width: 5%;
        transform: translate(-60%, -50%);
    }

    .swiper-button-next {
        height: 30%;
        width: 5%;
        transform: translate(25%, -50%);
        right: 15px;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
        font-size: 2rem;
        // color: red;
        width: 5%;
    }
}

.fieldSwiperSliderSection {
    .swiper-pagination  {
        // margin-bottom: -1rem;
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
    }
}


.footersection {
    padding-top: 1rem;

    .footer {
        margin-top: 1.25rem;
        padding: 0.625rem;
        background-color: #F5F9FB !important;
        width: 100%;
    }

    .title {
        font-size: 1rem;
        color: $colorPrimary;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-weight: bold;

    }

    .secondthoughtlogo {
        display: block;
        width: 8rem;
        height: 4rem;
        text-align: center;
        margin: 1.25rem;
    }
}


.firstSection {
    // padding-top:3.125rem;
    margin-top: -1rem;
}

.attributeTitle {
    color: $colorSecondary !important;
    text-transform: uppercase !important;
    font-size: 0.875rem;
    font-weight: bold;
    display: block;
}

.attributeValue {
    color: $colorSuccess !important;
    font-size: 1rem;
    font-weight: $regular;
    display: block;
}

.attributeLinkValue {
    cursor: pointer;
    color: $colorSuccess !important;
    font-size: .875rem;
    font-weight: $medium;
    text-transform: uppercase !important;
    word-wrap: break-word;
}

.claimBlock {
    display: inline-flex !important;
}

.claimImage {
    width: 2rem !important;
    height: 2rem !important;
    margin-right: 0.75rem;
}

.claimItem {
    color: $colorSuccess !important;
    font-size: 1.125rem;
    font-weight: $medium;
    //display: inline-block;
    margin-left: 0.625rem;
}

.productTitle {
    font-size: 1.75rem;
    font-weight: bold;
}

.productSubTitle {
    font-size: 1.125rem;
    color: $colorWarning;
    font-weight: bold;
}

.ingredients {
    font-size: 1.125rem;
    color: $colorWarning;
    font-weight: $regular;
}

.titleDiv {
    padding-bottom: 0.625rem;
    border-bottom: 2px solid $colorPrimary !important;
    padding-top: 1.5rem;

}

.attrDiv {
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $colorBlue !important;
}

.lastItem {
    margin-bottom: 0.875rem !important;
}

.textLeft {
    text-align: start !important;
}

.textRight {
    text-align: end !important;
}

.nutrientSubTitle {
    color: $colorWarning !important;
    text-transform: capitalize !important;
    font-size: 1.125rem;
    font-weight: $regular;
    display: block;
}

.nutrientTitle {
    color: $colorSuccess !important;
    text-transform: capitalize !important;
    font-size: 1.125rem;
    font-family: $fontFamilySemibold;
    font-weight: bold;
    display: block;
}

.nutrientValue {
    color: $colorSuccess !important;
    font-size: 1.25rem;
    font-weight: $regular;
    display: block;
}

.feedbackText {
    width: 100%;
    height: 10rem;
    border: 0.0625rem solid $colorInfo;
    border-radius: 0.3125rem;
    text-align: start;
    padding: .4375rem;
}

.feedback-container {
    &.hide {
        display: none;
    }

    &.show {
        display: block;
    }
}

.feedbackRating {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__promotion {
        display: flex;
        justify-content: space-around;
        width: 22rem;
        align-self: center;

        &__detractors {
            display: flex;
            justify-content: space-around;
            width: 58%;
            color: red;
        }

        &__passive {
            display: flex;
            justify-content: space-around;
            width: 20%;
            color: yellow;
        }

        &__promoters {
            display: flex;
            justify-content: space-around;
            width: 11%;
            color: green;
        }
    }

    &__score {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22rem;
        height: 2.5rem;
    }

    &__instruction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;
        width: 22rem;
    }
}

.ratingButton {
    outline: none;
    background-color: white;
    color: black;
    border: solid 0.02rem transparent;
    margin: 0.2rem;
    width: 2rem;
    height: 2rem;
    border-radius: .2rem;
    box-shadow: 0 0 0 0.1rem transparent;

    @media (max-width: 576px) {
        width: 1.5rem;
        height: 1.7rem;
    }

    &[data-selected="true"] {
        margin: 0.1rem;
        width: 2.3rem;
        height: 2.3rem;
        box-shadow: 0 0 0 0.1rem rgba(53, 187, 220, 0.5);
        // box-shadow: inset 0 -2px 0 0 #cdcde6,inset 0 0 1px 1px #fff,0 1px 2px 1px rgba(30,35,90,0.4);

        @media (max-width: 576px) {
            width: 2rem;
            height: 2rem;
        }

    }
}

.email {
    color: $colorSuccess !important;
    text-transform: capitalize !important;
    font-size: 1rem;
    font-weight: bold;
    display: inline-block;

    .subTitle {
        color: $colorSecondary !important;
        text-transform: capitalize !important;
        font-size: 0.8rem;
        font-weight: bold;
    }

    .subTitleRed {
        color: #dc3545 !important;
        text-transform: capitalize !important;
        font-size: 0.8rem;
        font-weight: bold;
    }
}

.emailBox {
    width: 100%;
    height: 3rem;
    border: 0.0625rem solid $colorInfo;
    border-radius: 0.3125rem;
}

.checkbox {
    width: 1.5rem;
    height: 1.5rem;
    border: 0.125rem solid $colorInfo;
    border-radius: 0.3125rem;
    margin-right: 0.625rem;

    :checked {
        background-color: $colorPrimary !important;
    }
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
    background-color: $colorPrimary;
    border: 1px solid $colorPrimary;
    width: 100%;
    color: $colorWhite;
    font-weight: bold;
    font-size: 1.125rem;
    height: 3rem;
    border-radius: 0.3125rem;
}

.info {
    font-size: 1rem;
    color: $colorSecondary !important;
}

.policyLink {
    font-size: 1rem;
    color: $colorSecondary !important;
    text-decoration: underline;
}

.loader {
    height: 100vh; //vh - viewport height
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image {
        vertical-align: middle;
        -webkit-animation: none
    }

    .box {
        vertical-align: middle;
    }
}

.logo {
    object-fit: contain;
    // height: 1.3125rem;
    max-height: 2.5rem;
    margin-left: 1rem;
    max-width: 7.125rem;
}

.header {
    // min-height: 5.125rem !important;
    // position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;

    @media (min-width:768px) {
        min-height: 4.125rem !important;
        position: fixed;
    }

    .navbar {

        // min-height: 5.125rem !important;
        @media (min-width: 768px) {
            min-height: 4.125rem !important;
        }

        .container-fluid {
            margin: 0.1rem;
        }
    }
}

// .collapse{
//     position: absolute;
//     top: 5.1rem !important;
//     left: 0;
//     z-index: 100000;
// }

.all-adt-lable {
    display: inline-block;
    font-size: 12px;
    border: 1px solid #22323d;
    border-radius: 15px;
    padding: .0625rem .25rem;
    margin: .125rem;
    line-height: 12px;
}

.allerg-adt-subtitle {
    color: $colorSuccess !important;
    text-transform: capitalize !important;
    font-size: 13px;
    font-family: $fontFamilySemibold;
    font-weight: bold;
    display: block;
}

.language-select {
    background-color: #22323d;
    border-color: #ffffff;
    border-radius: 12px;
    width: 100px;
    color: #ffffff;
}

.view-more-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    >a {
        color: #CF2948 !important;
        text-transform: capitalize;
        font-size: 16px;
        font-family: $fontFamily;
        font-weight: bold;
        cursor: pointer;
        align-self: center;
        text-align: center;
        text-transform: uppercase !important;
    }
}

.header-collapse {
    position: absolute;
    z-index: -1;
    top: -260px;
    transition: top 0.45s ease-out;
}

.header-show {
    position: absolute;
    z-index: 2;
    top: 3rem;
    transition: top 0.45s ease-in;

    @media (min-widhth: 768px) {
        top: 4rem;
    }
}

.searchPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    &__search {
        margin: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        &__input {
            margin-bottom: 1rem;
            width: 460px;
            max-width: 98vw;
            height: 48px;
            outline: none;
            border: 0px;
            background-color: #ffffff;
            align-self: center;
            border-radius: 12px;
            padding-right: 24px;
            padding-left: 24px;
            font-size: 16px;
        }

        &__button {
            margin-bottom: 1rem;
            opacity: 1 !important;
            height: 48px;
            width: 119px;
            border-radius: 12px;
            align-self: center;
            font-size: 14px;
            margin-left: 11px;
            font-weight: normal;
        }
        &__bottomlabel{
            text-align: center;
            color:black;
            font-size: 16px;
            align-self: center;
            margin-left: 11px;
            margin-right: 11px;
        }
        &__bottomlink{
            color:blue !important;
            cursor: pointer;
            text-decoration: underline !important;
            font-size: 16px;
        }
    }
}

.confirm_button {
    width: 180px;
    height: 40px;
    background-color: #cf2948 !important;
    border-radius: 5px;
    opacity: 1;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 15px;
    // font-family: $proxima-nova;
    font-weight: bold;
    text-align: center;
    cursor: pointer !important;
}

.close-text {
    color: #d1334c;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    // font-family: $proxima-nova;
    cursor: pointer;
}


.modelViewer {
    // display: flex;
    // align-items: center;
    // justify-content: center;
}

.modelViewer model-viewer {
    width: 100%;
    height: 350px;
}

.noticeDiv {
    font-size: 18px;
    margin-left: 5px;

    @media (max-width: 576px) {
        font-size: 15px;
    }
}

.noticeInfo {
    line-height: 20px;

    @media (max-width: 576px) {
        font-size: 14px;
    }

}

@keyframes swipe {
    0% {
        margin: 0;
        transform: rotate(0deg);
    }

    100% {
        margin-right: 30px;
        transform: rotate(-5deg);
    }
}

.swipeIcon {
    position: fixed;
    z-index: 10;
    bottom: 65px;
    right: 47%;
    font-size: 35px;
    color: #dc3545;
    animation: swipe .8s alternate infinite;
    animation-timing-function: ease;

    @media (max-width: 768px) {
        right: 44%;
    }

}

.productSearch {
    &__search {}
}

//style={{height:200,width:300,backgroundColor:'#000000',zIndex:10,position:'fixed',margin:'auto',top:'50%'}}


.loading-overlay {
    // z-index: 100000;
    // elevation: 100000;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    // width: 100%;
    // height: 110%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -2.1rem;

    // &--stripePromise {
    //     height: 127%;
    // }
}


.upidsPayment {
    &__heading {
        font-size: 1.2rem;
        margin-top: -1rem;
        margin-bottom: 1rem;
        font-weight: bold;
    }



    &__card {

        input,
        .StripeElement {
            display: block;
            margin: 1rem .1rem .1rem 0rem;
            max-width: 500px;
            padding: .8rem 1rem;
            font-size: 1em;
            box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
                rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
            border: 0;
            outline: 0;
            background-color: #f8fcfd;
            border-radius: .5rem;
            border: 1px solid rgb(197, 207, 214);
        }

        input::placeholder {
            color: #aab7c4;
        }

        input:focus,
        .StripeElement--focus {
            box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
                rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
            -webkit-transition: all 150ms ease;
            transition: all 150ms ease;
        }

        .StripeElement.IdealBankElement,
        .StripeElement.FpxBankElement,
        .StripeElement.PaymentRequestButton {
            padding: 0;
        }

        .StripeElement.PaymentRequestButton {
            // height: 40px;
        }



        &__number {
            width: 12rem;
        }

        &__expiry {
            width: 5rem;
        }

        &__cvc {
            width: 5rem;
        }

    }

    &__success {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem;

        &__text {
            color: green;
            font-size: 1rem;
        }

        &__okbtn {
            padding-top: 1rem;
        }

    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;

        &__text {
            margin-top: 1rem;
            margin-bottom: 0rem;
            font-size: .9rem;

            &__strip {
                margin-left: .2rem;
                margin-right: .2rem;
                color: blue;
                cursor: pointer;
            }
        }

        &__icon {
            background-image: url(../img/stripe.svg);
            background-repeat: no-repeat;
            height: 2rem;
            width: 4rem;
            align-self: flex-end;
        }
    }
}

.payment-input {
    background-color: rgb(248, 252, 253);
    border-radius: 7px;
    border: 1px solid rgb(197, 207, 214);
    font-size: 12px;
    padding: 6px;
    width: 100%;
}

.margin-top-10 {
    margin-top: 10px;
}

.float-left {
    float: left;
    margin-right: .5rem;
}

.red-text {
    color: red;
}

.login-input {
    background-color: #ffffff;
    width: 100%;
    height: 40px;
    border: 1px solid #203441;
    border-radius: 5px;
    margin: 16px 0 0 0;
    padding-left: 16px;
    font-family: 'Poppins';
    outline: none;
}

.login-input:focus {
    border: 1px solid #203441;
}

.login-input ::placeholder {
    color: #455c6c;
    font-size: 16px;
    font-family: 'Poppins';
}


.login-button {
    background: #cf2948 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    height: 40px;
    width: 200px;
    border: none;
    color: white;
    margin-top: 16px;
    font-family: 'Poppins';
}

.login-button:disabled {
    background: #c08590 0% 0% no-repeat padding-box;
}

.login-button:hover {
    background-color: #ac1d37;
}

.login-lable {
    font-size: 16px;
    color: #ffffff;
}
.doormat-overlay-header-style{
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
}
.doormat-labels-style{
    color: $colorWhite;
    border: 1px solid $colorPrimary;
    padding: 15px;
    display: flex;
    width: fit-content;
    margin: 0 auto;
    background-color: $colorPrimary;
    cursor: pointer;
    height: 3rem;
    border-radius: 0.3125rem;
}
.doormat-labels-style1{
    width: 200px !important;
    height: 70px !important;
}
.custom-modal-header {
    border: none;
  }
.manufacturerPrivacyPolicyTextStyle {
    cursor: pointer;
    border-bottom: 1px solid #7994A7;
}