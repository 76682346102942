////////////////////////////////////////////////////////////////
// Bootstrap 5
////////////////////////////////////////////////////////////////
// @import url('../bootstrap/bootstrap.min.css');

////////////////////////////////////////////////////////////////
// Plugins
////////////////////////////////////////////////////////////////
// @import url('../splide/splide.min.css');

////////////////////////////////////////////////////////////////
// Ion Icons
////////////////////////////////////////////////////////////////
@import url('https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css');

////////////////////////////////////////////////////////////////
// Template Settings
////////////////////////////////////////////////////////////////
// Font Settings
@import 'font';
// Theme Variables
@import 'variables';

////////////////////////////////////////////////////////////////
// Layout
////////////////////////////////////////////////////////////////
// Body - General Settings
@import 'body';

@import 'layout/layoutWrapper';

// Header
@import 'layout/header';
// Bottom Menu
@import 'layout/bottomMenu';
// Content
@import 'layout/content';
// Sidebar
@import 'layout/sidebar';
// Footer
@import 'layout/footer';

////////////////////////////////////////////////////////////////
// UI Kit
////////////////////////////////////////////////////////////////
@import 'ui';

////////////////////////////////////////////////////////////////
// Blocks
////////////////////////////////////////////////////////////////
@import 'blocks';

////////////////////////////////////////////////////////////////
// Dark Mode
////////////////////////////////////////////////////////////////
@import 'darkmode';

@import 'custom';
