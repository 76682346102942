.timeline{
    position: relative;
    padding: 24px 0;
    &:before{
        content: '';
        display: block;
        position: absolute;
        width: 2px;
        left: 0;
        bottom: 0;
        top: 0;
        background: $colorLine;
        z-index: 1;
    }
    .item{
        position: relative;
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .content{
        padding-left: 20px;
        .title{
            margin-bottom: 8px;
            line-height: 1.3em;
        }
        .text{
            font-size: $fontSizeSub;
            line-height: 1.4em;
        }
    }
    .dot{
        width: 12px;
        height: 12px;
        border-radius: 100%;
        position: absolute;
        background: $colorLight;
        left: -5px;
        top: 4px;
        z-index: 10;
    }

}
.timeline.timed{
    padding-left: 80px;
    &:before{
        left: 80px;
    }
    .time{
        font-size: $fontSizeCaption;
        position: absolute;
        left: -80px;
        line-height: 1.5em;
        width: 70px;
        text-align: right;
        top: 1px;
        z-index: 20;
    }
}