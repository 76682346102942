.showMoreLess {
    display: inline;
    width: 100%;

    &__read-or-hide {
        color: rgb(192, 192, 192);
        cursor: pointer;
        margin-left: 0.3rem;
        color: rgb(27 127 204 / 80%);
    }
}