// Define font weight
// We use this variable for font weights.
$regular : 400; // font regular
$medium : 500; // font medium (if font does not support medium, define the semibold)
$semibold: 500;
$bold : 700; // font bold or black


// @font-face {
//     font-family: 'Poppins';
//     src: local('Poppins'), url(../font/Poppins/Poppins-VariableFont_wght.ttf) format('truetype');
//     // font-display: auto;
//     // font-style: normal;
//     // font-weight: 900;
// }

// @font-face {
//     font-family: 'Poppins';
//     src: local('Poppins'), url(../font/Poppins/Poppins-Italic-VariableFont_wght.ttf) format('truetype');
//     // font-display: auto;
//     // font-style: normal;
//     // font-weight: 900;
// }


// -----

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-Black.ttf) format('truetype');
    font-display: auto;
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-BlackItalic.ttf) format('truetype');
    font-display: auto;
    font-style: italic;
    font-weight: 900;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-ExtraBold.ttf) format('truetype');
    font-display: auto;
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-ExtraBoldItalic.ttf) format('truetype');
    font-display: auto;
    font-style: italic;
    font-weight: 800;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-Bold.ttf) format('truetype');
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-BoldItalic.ttf) format('truetype');
    font-display: auto;
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-SemiBold.ttf) format('truetype');
    font-display: auto;
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-SemiBoldItalic.ttf) format('truetype');
    font-display: auto;
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-Medium.ttf) format('truetype');
    font-display: auto;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-MediumItalic.ttf) format('truetype');
    font-display: auto;
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-Regular.ttf) format('truetype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-Italic.ttf) format('truetype');
    font-display: auto;
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-Light.ttf) format('truetype');
    font-display: auto;
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-LightItalic.ttf) format('truetype');
    font-display: auto;
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-ExtraLight.ttf) format('truetype');
    font-display: auto;
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-ExtraLightItalic.ttf) format('truetype');
    font-display: auto;
    font-style: italic;
    font-weight: 200;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-Thin.ttf) format('truetype');
    font-display: auto;
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../font/popins/Poppins-ThinItalic.ttf) format('truetype');
    font-display: auto;
    font-style: italic;
    font-weight: 100;
}


@font-face {
    font-family: 'Poppins-semibold';
    src: local('Poppins'), url(../font/popins/Poppins-SemiBold.ttf) format('truetype');
    font-display: auto;
    font-style: normal;
    font-weight: 500;
}


$fontFamily: "Poppins", sans-serif;
$fontFamilySemibold: "Poppins", sans-serif;