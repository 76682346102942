.appBottomMenu {
  min-height: 56px;
  position: fixed;
  z-index: 999;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $colorLine;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: env(safe-area-inset-bottom);

  &.no-border {
    border: 0 !important;
    box-shadow: 0 !important;
  }

  .item {
    font-size: 9px;
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    height: 56px;
    line-height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
      content: '';
      display: block;
      height: 2px;
      border-radius: 0 0 10px 10px;
      background: transparent;
      position: absolute;
      left: 4px;
      right: 4px;
      top: 0;
    }

    .col {
      width: 100%;
      padding: 0 4px;
      text-align: center;
    }

    .icon,
    ion-icon {
      display: inline-flex;
      margin: 1px auto 3px auto;
      font-size: 24px;
      line-height: 1em;
      color: $colorHeading;
      transition: 0.1s all;
      display: block;
      margin-top: 1px;
      margin-bottom: 3px;
    }

    .action-button {
      display: inline-flex;
      width: 50px;
      height: 50px;
      margin-left: -5px;
      margin-right: -5px;
      align-items: center;
      justify-content: center;
      border-radius: 200px;
      background: $colorPrimary;

      &.large {
        width: 60px;
        height: 60px;
        margin-top: -20px;
        margin-left: -10px;
        margin-right: -10px;
      }

      .icon,
      ion-icon {
        color: #FFF !important;
        margin: 0 !important;
        line-height: 0 !important;
      }
    }

    strong {
      margin-top: 4px;
      display: block;
      color: $colorHeading;
      font-weight: $regular;
      transition: 0.1s all;
    }

    &:active {
      opacity: .8;
    }

    &.active {
      &:before {
        background: $colorPrimary;
      }

      .icon,
      ion-icon,
      strong {
        color: $colorPrimary !important;
        font-weight: $medium;
      }
    }

    &:hover {

      .icon,
      ion-icon,
      strong {
        color: $colorHeading;
      }
    }
  }

  &.text-light {
    color: #FFF;

    .item {
      color: #FFF;
      opacity: .7;

      .icon,
      ion-icon,
      strong {
        color: #FFF;
      }

      &.active {
        opacity: 1;

        .icon,
        ion-icon,
        strong {
          color: #FFF !important;
        }
      }
    }
  }

  &.bg-primary,
  &.bg-secondary,
  &.bg-success,
  &.bg-warning,
  &.bg-danger,
  &.bg-info,
  &.bg-light,
  &.bg-dark {
    border: 0;

    .item {
      &:before {
        display: none;
      }
    }
  }
}

.bottomMenu {
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 40rem;
  height: 3.5rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: #2a2831;
  display: flex;
  overflow-x: auto;

  left: 50%;
  transform: translateX(-50%);

  @media (min-width:768px) {
    height: 4rem;
  }

  &::-webkit-scrollbar {
    width: 0px !important;
  }

  @media (max-width: 768px) {
    width: 100%;
  }


  .bottomMenu__item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    color: #e8def7;
    text-decoration: none;
    // -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;

    @media (min-width:768px) {
      margin-top: .2rem;
    }

    &[data-enabled="true"] {
      background: #ffffff40;
    }

    &:hover {
      // color: rgb(123, 123, 214);
    }

    i {
      font-size: 1rem;
    }

    div {
      font-size: 0.8rem;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}