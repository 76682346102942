.form-check {
  padding: 0;
  margin: 0;
  min-height: auto;
  height: auto;
  .form-check-input {
    display: none;
  }
  .form-check-label {
    cursor: pointer;
    position: relative;
    min-height: 20px;
    padding: 0px 0 0 32px;
    line-height: 1.7em;
    color: $colorHeading;
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      background: transparent;
      border-radius: 100px;
      border: 1px solid $colorLine;
    }
    &:before {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      opacity: 0;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
}
.form-check-input[type="radio"] ~ .form-check-label:before {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='8px' height='8px' viewBox='0 0 8 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Ccircle id='Oval' fill='%23FFFFFF' cx='4' cy='4' r='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
}
.form-check-input:checked ~ .form-check-label {
  &:after {
    background-color: $colorPrimary;
    border-color: $colorPrimary !important;
  }
  &:before {
    opacity: 1;
  }
}
.form-check-inline {
  margin-right: 0;
  .form-check-label {
    margin-bottom: -3px;
    padding-left: 24px;
  }
}

.input-list {
  .form-check {
    &:after {
      content: "";
      height: 1px;
      background: $colorLine;
      display: block;
      margin-left: 54px;
    }
    .form-check-label {
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.1s all;
      padding: 6px 16px 6px 54px;
      &:before,
      &:after {
        left: 16px;
        top: 14px;
      }
      &:active {
        background: rgba($colorLine, 0.3);
      }
    }
    &:last-child {
      &:after {
        height: 0;
      }
    }
  }
}
.form-switch {
  height: 30px;
  .form-check-label {
    width: 56px;
    height: 30px;
    padding: 0;
    margin: 0;
    &:after {
      height: 30px;
      width: 56px;
      background: $colorLine;
      border: 1px solid darken($colorLine, 5%);
      transition: .1s linear;
    }
    &:before {
      opacity: 1;
      border-radius: 100px;
      width: 24px;
      height: 24px;
      top: 3px;
      left: 4px;
      background: #ffffff;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
      transition: .15s ease-in-out;
    }
  }
  .form-check-input:checked ~ .form-check-label {
    &:before {
      left: 28px;
    }
  }
}