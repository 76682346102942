// Add to home component based on action sheet component.

.ios-add-to-home {
    .modal-header {
        border-bottom-color: transparent !important;
    }
    .modal-content {
        position: relative;
        margin-bottom: 10px;
        z-index: 1;
        box-shadow: 0 10px 20px -4px rgba(0, 0, 0, 0.2) !important;
        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-top: 10px solid #fff;
            position: absolute;
            bottom: -7px;
            z-index: 10;
            left: 50%;
            margin-left: -16px;
        }
        .action-sheet-content {
            font-size: $fontSizeSub;
            color: $colorHeading;
            line-height: 1.5em;
            h4 {
                font-size: $fontSize;
                margin-bottom: 10px;
            }
            i.bi,
            i.icon,
            ion-icon {
                font-size: 22px;
                margin-bottom: -4px;
            }
        }
    }
}

.android-add-to-home {
    .modal-header {
        border-bottom-color: transparent !important;
    }
    z-index: 200000;
    .modal-dialog {
        margin-top: $safeTop !important;
        top: 0;
        bottom: auto;
        padding: 6px !important;
        transform: translate(0, -100%) !important;
        transition: .5s all !important;
    }
    &.show .modal-dialog {
        transform: translate(0, 0) !important;
        top: 10px;
    }
    .modal-content {
        position: relative;
        z-index: 1;
        border-radius: 8px !important;
        box-shadow: 0 10px 20px -4px rgba(0, 0, 0, 0.2) !important;
        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #fff;
            position: absolute;
            top: -8px;
            z-index: 10;
            right: 8px;
            transform: rotate(180deg);
            margin-left: -16px;
        }
        .action-sheet-content {
            font-size: $fontSizeSub;
            color: $colorHeading;
            line-height: 1.6em;
            padding-top: 16px !important;
            i.bi,
            i.icon,
            ion-icon {
                font-size: 18px;
                margin-bottom: -5px;
            }
        }
    }
}
